import store, { dispatch, RootState, ProductName } from "@/store";
import router from "@/router";
import { request, url } from "@/api";

interface C {
  rootState: RootState;
}

export default {
  learnMore(c: C, name: ProductName) {
    const path = store.getters.getPath(name);
    if (/^https?:\/\/.*/.test(path)) {
      window.open(path, "_blank");
    } else {
      router.push(path);
    }
  },
  orderNow({ rootState }: C, name: ProductName) {
    const url = rootState.assets.url;
    const product = rootState.app.products.find(
      product => product.code === name
    );
    if (rootState.app.isCN) {
      const buyUrl = product?.cn_url || url.mall;
      if (buyUrl.includes(url.mall) || buyUrl.includes(url.m)) {
        dispatch.qrCodeSet({
          visible: true,
          title: "扫码购买",
          desc: `GET 最新产品资讯，还可以第一时间参与抢购<br /><a href="${buyUrl}">${buyUrl}<a />`,
          src: rootState.assets.oss.qr_code_buy,
          url: buyUrl
        });
      } else {
        window.open(buyUrl, "_blank");
      }
    } else {
      // if (name === "CYBERBLADE" && rootState.app.countryCode === "US") {
      //   window.open(
      //     " https://www.amazon.com/dp/B0BG4Z2W89/" || url.cart,
      //     "_blank"
      //   );
      // }
      window.open(product?.overseas_url || url.cart, "_blank");
    }
  },
  request(
    c: C,
    data: {
      name: ProductName;
      nickname: string;
      email?: string;
      mobile?: string;
    }
  ) {
    return request({
      url: url.request,
      method: "POST",
      data: {
        product_code: data.name,
        nickname: data.nickname,
        email: data.email,
        phone: data.mobile
      }
    });
  },
  requestInfo(c: C, name: ProductName) {
    return request({
      url: url.requestInfo + "?x=" + name
    });
  },
  requestCount(c: C, name: ProductName) {
    return request({
      url: url.requestCount + "?x=" + name
    });
  },
  followUs({ rootState }: C, name: ProductName) {
    const src = {
      CYBERBOARD: rootState.assets.oss.qr_code_cyberboard,
      "CYBERBOARD R2": rootState.assets.oss.qr_code_cyberboard_r2,
      "CYBERBOARD R3": rootState.assets.oss.qr_code_cyberboard_gb,
      "CYBERBOARD R4": rootState.assets.oss.qr_code_cyberboard,
      "CYBERBOARD XMAS": rootState.assets.oss.qr_code_cyberboard,
      "CYBERBOARD GB": rootState.assets.oss.qr_code_cyberboard_gb,
      "CYBERBOARD GLACIER R2": rootState.assets.oss.qr_code_cyberboard_gb,
      "CYBERBOARD TERMINAL": rootState.assets.oss.qr_code_cyberboard_terminal,
      "CYBERBOARD TYPING KIT": rootState.assets.oss.qr_code_cyberboard_r2,
      "CYBERBOARD GOLD PAISLEY": rootState.assets.oss.qr_code_cyberboard,
      "CYBERBOARD NOVEL 2": rootState.assets.oss.qr_code_cyberboard_r2,
      "THE NOVEL PROJECT": rootState.assets.oss.qr_code_cyberboard_r2,
      "COSMO KILLER": rootState.assets.oss.qr_code_am_65_less,
      "COSMO KILLER R2": rootState.assets.oss.qr_code_am_afa_r2,
      "COSMO KILLER DUNE": rootState.assets.oss.qr_code_am_afa_r2,
      "AM 65 LESS": rootState.assets.oss.qr_code_am_65_less,
      "AM 65 LESS R2": rootState.assets.oss.qr_code_am_65_less,
      "AM 65 LESS FADING": rootState.assets.oss.qr_code_am_65_less,
      "AM RGB 65": rootState.assets.oss.qr_code_cyberboard,
      "AM COMPACT TOUCH SPLIT":
        rootState.assets.oss.qr_code_am_compact_touch_split,
      "AM AFA": rootState.assets.oss.qr_code_am_afa,
      "AM AFA R2": rootState.assets.oss.qr_code_am_afa_r2,
      "AM AFA BLADE": rootState.assets.oss.qr_code_am_afa_r2,
      "AM HATSU": rootState.assets.oss.qr_code_am_hatsu,
      "AM HATSU BATTLESHIP": rootState.assets.oss.qr_code_am_hatsu,
      "AM RELIC 80": rootState.assets.oss.qr_code_cyberboard,
      "AM RELIC 80 OG": rootState.assets.oss.qr_code_cyberboard,
      "AM NEON 80": rootState.assets.oss.qr_code_cyberboard,
      CYBERMAT: rootState.assets.oss.qr_code_cybermat,
      "CYBERMAT R2": rootState.assets.oss.qr_code_cybermat,
      CYBERCHARGE: rootState.assets.oss.qr_code_cybercharge,
      "CYBERCHARGE 35": rootState.assets.oss.qr_code_cybercharge,
      CYBERCOIN: rootState.assets.oss.qr_code_cybermat,
      CYBERSWITCH: rootState.assets.oss.qr_code_cyberboard,
      CYBERBLADE: rootState.assets.oss.qr_code_cyberblade,
      "AM EMPTINESS PHONECASE": rootState.assets.oss.qr_code_cybercharge,
      "AM EMPTINESS PHONECASE 16": rootState.assets.oss.qr_code_cybercharge,
      "AM EMPTINESS CHARGER": rootState.assets.oss.qr_code_cybercharge,
      "CARPIO AM EDITON": rootState.assets.oss.qr_code_wechat_club,
      "AM CARPIO G2": rootState.assets.oss.qr_code_wechat_club,
      HOVER: rootState.assets.oss.qr_code_am_afa,
      "EMERGENCE KEYCAPS": rootState.assets.oss.qr_code_cyberboard_gb,
      "ICY SILVER SWITCH": rootState.assets.oss.qr_code_cyberboard_gb,
      "ICY SILVER SWITCH PRO": rootState.assets.oss.qr_code_cyberboard_gb,
      "ICY SILVER SWITCH PRO V2": rootState.assets.oss.qr_code_cyberboard_gb,
      "GLACIER KEYCAP SET": rootState.assets.oss.qr_code_cyberboard_gb,
      "ANGRY MIAO 021C": rootState.assets.oss.qr_code_angry_miao_021c,
      "BACK TO THE FUTURE R2": rootState.assets.oss.qr_code_angry_miao_021c,
      "DANCING CAT": rootState.assets.oss.qr_code_angry_miao_021c,
      "THE CHIMNEY CAT": rootState.assets.oss.qr_code_the_chimney_cat,
      "TIME MACHINE": rootState.assets.oss.qr_code_time_machine,
      EXT: rootState.assets.oss.qr_code_ext,
      AMTOYS: rootState.assets.oss.qr_code_amtoys
    };

    switch (rootState.app.language) {
      case "cn":
        dispatch.qrCodeSet({
          visible: true,
          title: "扫码进群",
          desc: "更多产品动态，优惠福利等着你哦！",
          src: src[name]
        });
        break;
      default:
        if (name === "CYBERBLADE") {
          window.open(rootState.assets.url.discord_cyberblade, "_blank");
        } else if (name === "AM AFA") {
          window.open(rootState.assets.url.discord_am_afa, "_blank");
        } else {
          window.open(rootState.assets.url.discord, "_blank");
        }
        break;
    }
  },
  contactUs({ rootState }: C) {
    if (rootState.app.isCN) {
      dispatch.qrCodeSet({
        visible: true,
        title: "微信扫一扫",
        desc: "联系我们",
        src: rootState.assets.oss.qr_code_wechat
      });
    } else {
      window.open(
        rootState.assets.url.contact_us + "?language=" + rootState.app.language,
        "_blank"
      );
    }
  },
  order(c: C, orderNo: string) {
    return request({
      url: url.order + "/" + orderNo
    });
  },
  AMAfaCustom(
    c: C,
    {
      orderNo,
      platform
    }: {
      orderNo: string;
      platform: string;
    }
  ) {
    return request({
      url: url.AMAfaCustomX + "/" + orderNo + "/" + platform
    });
  },
  AMAfaCustomPost(
    c: C,
    data: {
      custom_info: {
        left: string;
        right: string;
        top: string;
      };
      order_no: string;
      email: string;
      platform: string;
    }
  ) {
    return request({
      url: url.AMAfaCustom,
      method: "POST",
      data
    });
  },
  draw(c: C, code: string) {
    return request({
      url: url.draw + "/x/" + code
    });
  },
  drawPost(
    c: C,
    data: {
      code: string;
      nickname: string;
      phone: string;
      email: string;
    }
  ) {
    return request({
      url: url.draw,
      method: "POST",
      data
    });
  }
};
