import { Commit } from "vuex";
import { RootState, ListQuery } from "@/store";
import { auth } from "@/utils";
import { request, url } from "@/api";

export interface User {
  avatar?: string;
  nickname?: string;
  id?: number;
  bind_phone?: string;
}

export interface PermissionItem {
  index: number;
  action: string;
  status: "allow" | "deny";
}

export interface Permission {
  index: number;
  name: string;
  items: PermissionItem[];
}

export interface Group {
  id: number;
  name: string;
  name_en: string;
}

export interface UserState {
  avatar: string;
  nickname: string;
  bind_phone: string;
  walletAddress: string;
  balance: any[];
  balanceList: any[];
  balanceQuery: ListQuery;
  balanceCount: number;
  id: number;
  isSignIn: boolean;
  permission: Permission[];
  groupList: Group[];
  group: Group[];
  inHouseUserIds: number[];
}

const state: UserState = {
  avatar: "",
  nickname: "",
  bind_phone: "",
  walletAddress: "",
  balance: [],
  balanceList: [],
  balanceQuery: {
    page_number: 1,
    page_size: 20
  },
  balanceCount: 0,
  id: 0,
  isSignIn: false,
  permission: [],
  groupList: [],
  group: [],
  inHouseUserIds: []
};

interface Context {
  commit: Commit;
  state: UserState;
  rootState: RootState;
}

const actions = {
  async signCheck() {
    const token = auth.getToken();
    const refreshToken = auth.getRefreshToken();
    if (token) {
      return true;
    } else if (refreshToken) {
      window.open(
        url.authRefresh +
          "?redirect=" +
          encodeURIComponent(window.location.href),
        "_self"
      );
      return false;
    } else {
      auth.signOut();
      return false;
    }
  },
  signIn() {
    window.open(
      url.auth + "?redirect=" + encodeURIComponent(window.location.href),
      "_self"
    );
  },
  signOut() {
    auth.signOut();
    window.open(
      url.auth + "?redirect=" + encodeURIComponent(window.location.href),
      "_self"
    );
  },
  get({ commit }: Context) {
    if (auth.getToken()) {
      return request({
        url: url.authProfile
      }).then((res: any) => {
        commit("SET_USER", {
          id: res.id,
          avatar: res.avatar,
          nickname: res.nickname,
          bind_phone: res.bind_phone,
          isSignIn: true
        });
      });
    }
    return;
  },
  getList(context: Context, query: ListQuery) {
    return request({
      url: url.user,
      params: query
    });
  },
  getById(context: Context, id: number) {
    return request({
      url: url.user + "/" + id
    });
  },
  patch({ commit }: Context, user: User) {
    request({
      method: "post",
      url: url.authProfile,
      data: user
    }).then((res: any) => {
      commit("SET_USER", {
        id: res.id,
        avatar: res.avatar,
        nickname: res.nickname || "请设置昵称",
        bind_phone: res.bind_phone,
        isSignIn: true
      });
    });
  },
  async balance({ commit }: Context, coin_type_id?: number) {
    const res: any = await request({
      url: url.balance,
      params: { coin_type_id }
    });
    commit("SET_BALANCE", res.count_list);
    commit("SET_WALLET_ADDRESS", res.wallet_address);
    return res;
  },
  async balanceList({ commit, state }: Context, query: ListQuery) {
    const res: any = await request({
      url: url.balanceHistory,
      params: query
    });
    commit(
      "SET_BALANCE_LIST",
      query.page_number == 1
        ? res.results
        : [...state.balanceList, ...res.results]
    );
    commit("SET_BALANCE_QUERY", {
      ...query,
      page_number: res.page_number,
      page_size: res.page_size
    });
    commit("SET_BALANCE_COUNT", res.count);
    return res;
  },
  taskRecord(context: Context, query: ListQuery) {
    const Languages: any = {
      cn: "chinese",
      en: "english",
      ja: "japanese"
    };
    query.language = Languages[query.language || "en"];
    return request({
      url: url.taskRecord,
      params: query
    });
  },
  getPermission({ commit }: Context) {
    return request({
      url: url.permission
    }).then((res: any) => {
      commit("SET_PERMISSION", res.permission);
    });
  },
  getGroupList({ commit }: Context) {
    return request({
      url: url.group,
      params: {
        page_number: 1,
        page_size: 1000
      }
    }).then((res: any) => {
      commit("SET_GROUP_LIST", res.results.reverse());
    });
  },
  getGroupUsers(context: Context, query: ListQuery) {
    return request({
      url: url.groupUsers,
      params: query
    });
  },
  getGroup({ state, commit }: Context) {
    return request({
      url: url.groupCurrentUser
    }).then((res: any) => {
      const group = state.groupList.filter((group: Group) =>
        res.ids.includes(group.id)
      );
      commit("SET_GROUP", group);
    });
  },
  async getInHouseUserIds({ commit, state }: Context) {
    const group = state.groupList.find(
      (item: Group) => item.name === "内部员工"
    );
    if (state.inHouseUserIds.length) {
      return state.inHouseUserIds;
    } else if (group) {
      const res: any = await request({
        url: url.groupUsers,
        params: {
          group_ids: [group?.id || 0],
          page_size: 1,
          page_number: 1
        }
      });
      const ids = res.results[0]?.ids || [];
      commit("SET_IN_HOUSEUSER_IDS", ids);
      return ids;
    }
    return [];
  },
  walletTransfer(context: Context, data: any) {
    return request({
      url: url.walletTransfer,
      method: "post",
      data
    });
  }
};

const mutations = {
  SET_USER: (state: UserState, user: UserState) => {
    state.id = user.id;
    state.avatar = user.avatar;
    state.nickname = user.nickname;
    state.bind_phone = user.bind_phone;
    state.isSignIn = user.isSignIn;
  },
  SET_BALANCE: (state: UserState, balance: any[]) => {
    state.balance = balance;
  },
  SET_WALLET_ADDRESS: (state: UserState, walletAddress: string) => {
    state.walletAddress = walletAddress;
  },
  SET_PERMISSION: (state: UserState, permission: Permission[]) => {
    state.permission = permission;
  },
  SET_GROUP_LIST: (state: UserState, groupList: Group[]) => {
    state.groupList = groupList;
  },
  SET_GROUP: (state: UserState, group: Group[]) => {
    state.group = group;
  },
  SET_IN_HOUSEUSER_IDS: (state: UserState, ids: number[]) => {
    state.inHouseUserIds = ids;
  },
  SET_BALANCE_LIST: (state: UserState, list: any[]) => {
    state.balanceList = list;
  },
  SET_BALANCE_QUERY: (state: UserState, query: ListQuery) => {
    state.balanceQuery = query;
  },
  SET_BALANCE_COUNT: (state: UserState, count: number) => {
    state.balanceCount = count;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
