import Vue from "vue";
import Vuex from "vuex";
import modules from "./modules";
import actions from "./actions";
import getters from "./getters";

Vue.use(Vuex);

export default new Vuex.Store({
  actions,
  modules,
  getters,
});

export type ProductName =
  | "CYBERBOARD"
  | "CYBERBOARD R2"
  | "CYBERBOARD R3"
  | "CYBERBOARD R4"
  | "CYBERBOARD XMAS"
  | "CYBERBOARD GB"
  | "CYBERBOARD GLACIER R2"
  | "CYBERBOARD TERMINAL"
  | "CYBERBOARD TYPING KIT"
  | "CYBERBOARD NOVEL 2"
  | "THE NOVEL PROJECT"
  | "COSMO KILLER"
  | "COSMO KILLER R2"
  | "COSMO KILLER DUNE"
  | "AM 65 LESS"
  | "AM 65 LESS R2"
  | "AM 65 LESS FADING"
  | "AM RGB 65"
  | "AM COMPACT TOUCH SPLIT"
  | "AM AFA"
  | "AM AFA R2"
  | "AM AFA BLADE"
  | "AM HATSU"
  | "AM HATSU BATTLESHIP"
  | "AM RELIC 80"
  | "AM RELIC 80 OG"
  | "AM NEON 80"
  | "CYBERMAT"
  | "CYBERMAT R2"
  | "CYBERCHARGE"
  | "CYBERCHARGE 35"
  | "CYBERCOIN"
  | "CYBERSWITCH"
  | "CYBERBLADE"
  | "CARPIO AM EDITON"
  | "AM CARPIO G2"
  | "AM EMPTINESS PHONECASE"
  | "AM EMPTINESS PHONECASE 16"
  | "AM EMPTINESS CHARGER"
  | "HOVER"
  | "EMERGENCE KEYCAPS"
  | "ICY SILVER SWITCH"
  | "ICY SILVER SWITCH PRO"
  | "ICY SILVER SWITCH PRO V2"
  | "GLACIER KEYCAP SET"
  | "ANGRY MIAO 021C"
  | "BACK TO THE FUTURE R2"
  | "DANCING CAT"
  | "THE CHIMNEY CAT"
  | "TIME MACHINE"
  | "EXT"
  | "AMTOYS";

  export type ProductStatus =
  | "developing"
  | "GB"
  | "inStock"
  | "restock"
  | "soldOut"

export { default as dispatch } from "./dispatch";

/* eslint-disable */
export type { RootState, ListQuery } from "@/store/modules";
export type { Oauth2, Product } from "@/store/modules/app";
export type { Media } from "@/store/modules/files";
export type { QrCodeState as  QrCode } from "@/store/modules/qrCOde";
export type { User, Group } from "@/store/modules/user";
export type { Task, TasksCategory, TasksTag, TasksVote, TasksChain } from "@/store/modules/tasks";
export type { Comment } from "@/store/modules/comments";
export type { Reward } from "@/store/modules/rewards";
export type { Notification } from "@/store/modules/notifications";
export type { Captcha, Verify } from "@/store/modules/verify";
